



































































































.title {
  color: #fff;
}
.chat-container {
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .header-chat {
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #ffffff2e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    button.back {
      padding: 0;
      background: transparent;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-size: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: #fff;
      color: #fff;
    }
  }
  .content-chat::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
  }

  .content-chat::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .content-chat::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  .content-chat {
    height: calc(100% - 120px);
    padding: 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .left-chat {
      background: #c5c5c5;
      min-height: auto;
      max-width: 300px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-bottom: 5px;

      span {
        color: black;
        word-break: break-word;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .right-chat {
      background: #224a6e;
      min-height: auto;
      max-width: 300px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      padding: 5px;
      margin-right: 0;
      margin-left: auto;
      margin-top: 5px;
      margin-bottom: 5px;

      span {
        color: #fff8f8;
        word-break: break-word;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .send-chat {
    height: 70px;
    border-radius: 10px;
    background: #ffffff2e;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding:0 10px;
    input.send {
      flex: 0 0 calc(90% - 15px);
      height: 40px;
      border-radius: 5px;
      color: #fff;
      background: #ffffff59;
      border: 0.5px solid #ffffff87 !important;
      outline: none;
      @media (max-width: 1599px){
        flex: 0 0 80%;
      }
      @media (max-width: 375px){
        flex: 0 0 75%;
      }
    }
    .button-send-group {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
       @media (max-width: 1599px){
        flex: 0 0 12%;
      }
      @media (max-width: 1299px){
        flex: 0 0 12%;
      }
      @media (max-width: 1099px){
        flex: 0 0 14%;
      }
      @media (max-width: 400px){
        flex: 0 0 15%;
      }
      @media (max-width: 375px){
        flex: 0 0 18%;
      }
      .send-button {
        border-radius: 50%;
        padding: 0;
        width: 40px;
        height: 40px;
        margin: 0;
        outline: none;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}

.chat-container {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid;
  border-radius: 10px;
}
.inputGroup {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  overflow: hidden;

  input {
    border: none;
    background-color: transparent;
    color: white;
    width: calc(100% - 80px);
  }

  button {
    width: 80px;
    border-radius: 5px;
  }

  input:active,
  input:focus {
    border: none;
    outline: none;
  }
}
.chat-container {
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 2px;
//   border: 1px solid #fff200;
  width: 100%;
  position: relative;
  margin: auto;
  border-radius: 10px !important;
}
