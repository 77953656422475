


















































































































































































.support {
  padding: 60px 20px 60px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  .support-action {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .card2 {
      width: 100%;
      background-color: #2d3035;
      padding: 20px;
      border-radius: 10px;
      color: rgba(153, 164, 176, 0.8);
      @media (max-width: 767px) {
        width: 80%;
      }
      @media (max-width: 575px) {
        width: 100%;
      }
      .form-control {
        border: 1px solid #2d3035;
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: #d4fa4d;
        option {
          background-color: rgb(41, 40, 40);
          border-radius: 15px;
          border: 0px;
        }
      }
      .custom-select {
        border: 1px solid #2d3035;
        background-color: rgba(84, 86, 88, 0.452);
        border-radius: 15px;
      }
      button {
        height: 50px;
        width: 7.25rem;
        color: #f5f6f7;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #5da000;
        background-image: conic-gradient(from 1turn, #458802, #7bc514);
        border-radius: 6.25rem;
        font-weight: bold;
        border: none;
      }
    }
  }
  .support-table {
    width: 100%;
    margin-bottom: 10px;
    table {
      color: white;
      position: relative;
      border-radius: 10px;
      thead {
        border-radius: 10px;
        tr {
          border-radius: 10px;
          th {
            border: none;
            position: sticky;
            top: 0;
            z-index: 10;
            background: #5da000;
          }
        }
      }
      tbody {
        tr {
          border: none;
          &:nth-child(odd) {
            color: rgb(185, 181, 181);
            background-color: rgba(90, 84, 84, 0.288);
          }
          &:nth-child(even) {
            color: rgb(223, 223, 226);
          }
          td {
            border: none;
          }
        }
      }
    }
    .table-responsive {
      height: auto;
      max-height: 600px;
      overflow-y: auto;
      border-radius: 10px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      // &::-webkit-scrollbar-thumb {
      //   background: rgb(60, 255, 0);
      //   border-radius: 10px;
      // }
    }
    .table-hover tbody tr:hover {
      color: rgb(59, 57, 57);
      background-color: rgba(255, 252, 252, 0.89);
    }
  }
}
